import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PortfolioContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px; /* Adjusted for the navbar height */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stacks items on mobile */
  }
`;

const ItemContainer = styled.div`
  background-color: white;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative; /* Necessary for the overlay */

  @media (max-width: 768px) {
    width: 100%; /* Take full width on mobile */
  }
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ItemName = styled.h2`
  margin: 0;
  font-size: 1.2rem;
`;

const TagsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto; /* Pushes tags to the right */

  @media (max-width: 768px) {
    margin-top: 10px; /* Adds some spacing on mobile */
  }
`;

const ItemTag = styled.span`
  background-color: ${(props) => (props.underConstruction ? '#FFC107' : '#61dafb')};
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;

  @media (max-width: 768px) {
    height: 300px; /* Adjust the height for mobile view */
  }

  &:hover .overlay {
    opacity: 1;
  }

  &:hover .visit-button {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PreviewIframe = styled.iframe`
  width: 1120px;
  height: 800px;
  border: none;
  transform: scale(0.5);
  transform-origin: top left;
  pointer-events: none;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 1000px;
    height: 950px;
    transform: scale(0.31); /* Scale down more for mobile but keep similar view */
    transform-origin: top left; /* Align it similarly to desktop */
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

const VisitButton = styled.a`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #61dafb;
  color: white;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(100%);

  &:hover {
    background-color: #21a1f1;
  }
`;

function PortfolioPage() {
  const [websites, setWebsites] = useState([]);

  useEffect(() => {
    // The key changes: we add the "Authorization" header
    fetch('https://rjfbago.pt:3002/api/portfolioItems', {
      headers: {
        'Authorization': 'Basic ' + btoa('admin@rjfbago.pt:password123')
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server error: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => setWebsites(data))
      .catch(error => console.error('Error fetching portfolio items:', error));
  }, []);

  return (
    <PortfolioContainer>
      {websites.map((site, index) => (
        <ItemContainer key={index}>
          <ItemHeader>
            <ItemName>{site.name}</ItemName>
            <TagsContainer>
              {site.tags &&
                site.tags.map((tag, tagIndex) => (
                  <ItemTag
                    key={tagIndex}
                    underConstruction={tag === 'under-construction'}
                  >
                    {tag}
                  </ItemTag>
                ))}
            </TagsContainer>
          </ItemHeader>
          <IframeContainer>
            <PreviewIframe src={site.url} title={site.name} />
            <Overlay className="overlay">{site.overlayComments}</Overlay>
            <VisitButton
              className="visit-button"
              href={site.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit
            </VisitButton>
          </IframeContainer>
        </ItemContainer>
      ))}
    </PortfolioContainer>
  );
}

export default PortfolioPage;
